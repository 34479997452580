import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Backdrop, Button, Menu, MenuItem, Modal } from '@mui/material';
import UserSvg from 'assets/user-3-line.svg';
import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

import { getProfileName } from '../../utils';
import ConfirmationDialog from '../confirmation-dialog';
import { ViewSavedFilters } from './ViewSavedFilters';
import './profile-icon.scss';

interface ProfileIconProps {
  userDetails: any;
  restrictedAccessUser: boolean;
  isAdmin: boolean;
}

const ProfileIcon: React.FC<ProfileIconProps> = ({
  userDetails,
  restrictedAccessUser,
  isAdmin
}) => {
  const { userImageUrl } = userDetails;
  const profileName = getProfileName(userDetails);
  const [anchorEl, setAnchorEl] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [openBookmarksModal, setOpenBookmarksModal] = useState(false);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleMenuClick = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleSignOutDialogOpen = () => {
    handleMenuClose();
    setDialogOpen(true);
  };

  const handleSignOutDialogClose = (agree = false) => {
    setDialogOpen(false);
    if (agree) {
      navigate('/logOut', { replace: true });
    }
  };

  const handleBookmarksModalOpen = () => {
    handleMenuClose();
    setOpenBookmarksModal(true);
  };

  const handleBookmarksModalClose = () => {
    setOpenBookmarksModal(false);
  };

  return (
    <div>
      <Button
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleMenuClick}
      >
        <div className="image-circle">
          <img
            className={userImageUrl ? 'image-url' : 'image-icon'}
            src={userImageUrl || UserSvg}
            alt="userIcon"
            width="30px"
            height="30px"
          />
        </div>
        <span className="profile-name-text" title={profileName}>
          {profileName}
        </span>
        <KeyboardArrowDownIcon className="down-caret" />
      </Button>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleMenuClose}
        PaperProps={{
          sx: {
            width: '173px',
            background: '#FFFFFF',
            boxShadow: '0px 10px 20px rgba(32, 8, 102, 0.15)'
          }
        }}
        MenuListProps={{
          'aria-labelledby': 'basic-button'
        }}
      >
        <Link className="menu-link" to="/profile">
          <MenuItem onClick={handleMenuClose}>My Info</MenuItem>
        </Link>
        {!restrictedAccessUser && isAdmin && (
          <Link className="menu-link" to="/ManageUsers">
            <MenuItem onClick={handleMenuClose}>Manage Users</MenuItem>
          </Link>
        )}
        <Link className="menu-link" to="/help">
          <MenuItem onClick={handleMenuClose}>Help and Support</MenuItem>
        </Link>
        {!restrictedAccessUser && <MenuItem onClick={handleBookmarksModalOpen}>Bookmarks</MenuItem>}
        <MenuItem onClick={handleSignOutDialogOpen}>Sign Out</MenuItem>
      </Menu>
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        open={openBookmarksModal}
        onClose={handleBookmarksModalClose}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 100
        }}
      >
        <ViewSavedFilters handleClose={handleBookmarksModalClose} />
      </Modal>
      <ConfirmationDialog
        title="Sign Out"
        content="Are you sure you want to sign out now?"
        actionYes="Yes"
        actionNo="Cancel"
        open={dialogOpen}
        handleDialogClose={handleSignOutDialogClose}
      />
    </div>
  );
};

export default ProfileIcon;
