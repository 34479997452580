import ContentCopyOutlinedIcon from '@mui/icons-material/ContentCopyOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import { Box, ButtonGroup, CircularProgress, IconButton, Tooltip, Zoom } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import { visuallyHidden } from '@mui/utils';
import axios from 'api-axios';
import insightsConfig from 'config/config';
import * as React from 'react';
import { sendErrorsToCW } from 'utils/apiService';

import './profile-icon.scss';

interface Data {
  filter_name: string;
  insights_url: string;
}

function descendingComparator<T>(a: T, b: T, orderBy: keyof T) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

type Order = 'asc' | 'desc';

function getComparator<Key extends keyof any>(
  order: Order,
  orderBy: Key
): (a: { [key in Key]: string }, b: { [key in Key]: string }) => number {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort<T>(array: readonly T[], comparator: (a: T, b: T) => number) {
  const stabilizedThis = array?.map((el, index) => [el, index] as [T, number]);
  stabilizedThis?.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis?.map((el) => el[0]);
}

interface EnhancedTableProps {
  onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Data) => void;
  order: Order;
  orderBy: string;
  rowCount: number;
}

function EnhancedTableHead(props: EnhancedTableProps) {
  const { order, orderBy, onRequestSort } = props;
  const createSortHandler = (property: keyof Data) => (event: React.MouseEvent<unknown>) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        <TableCell
          key="filter_name"
          align="left"
          padding="normal"
          sortDirection={orderBy === 'filter_name' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'filter_name'}
            direction={orderBy === 'filter_name' ? order : 'asc'}
            onClick={createSortHandler('filter_name')}
          >
            Name
            {orderBy === 'filter_name' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell
          key="insights_url"
          align="left"
          padding="normal"
          sortDirection={orderBy === 'insights_url' ? order : false}
        >
          <TableSortLabel
            active={orderBy === 'insights_url'}
            direction={orderBy === 'insights_url' ? order : 'asc'}
            onClick={createSortHandler('insights_url')}
          >
            Dashboard
            {orderBy === 'insights_url' ? (
              <Box component="span" sx={visuallyHidden}>
                {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
              </Box>
            ) : null}
          </TableSortLabel>
        </TableCell>
        <TableCell key="actions" align="left" padding="normal">
          Actions
        </TableCell>
      </TableRow>
    </TableHead>
  );
}

export default function SavedFiltersTable({
  filters,
  applyFilter,
  storageKey,
  getSavedFilters,
  isLoading,
  setShowDeleteAlert
}: any) {
  const [order, setOrder] = React.useState<Order>('asc');
  const [orderBy, setOrderBy] = React.useState<keyof Data>('filter_name');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event: React.MouseEvent<unknown>, property: keyof Data) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - filters.length) : 0;

  const visibleRows = React.useMemo(
    () =>
      stableSort(filters, getComparator(order, orderBy)).slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
      ),
    [filters, order, orderBy, page, rowsPerPage]
  );

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2, boxShadow: '3px 3px 20px 0px rgba(0,0,0,0.08)' }}>
        <TableContainer sx={{ maxHeight: '35rem' }}>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle" size="medium" stickyHeader>
            <EnhancedTableHead
              order={order}
              orderBy={orderBy}
              onRequestSort={handleRequestSort}
              rowCount={filters.length}
            />
            <TableBody>
              {isLoading || visibleRows.length === 0 ? (
                <TableRow>
                  <TableCell colSpan={3}>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        height: '10rem'
                      }}
                    >
                      {isLoading ? (
                        <CircularProgress style={{ color: '#FF5300' }} />
                      ) : (
                        'No Bookmarks to Display'
                      )}
                    </div>
                  </TableCell>
                </TableRow>
              ) : (
                visibleRows.length > 0 &&
                visibleRows.map((row) => {
                  return (
                    <TableRow hover key={row.filter_name} sx={{ cursor: 'pointer' }}>
                      <TableCell component="th" scope="row" onClick={() => applyFilter(row)}>
                        {row.filter_name}
                      </TableCell>
                      <TableCell align="left" onClick={() => applyFilter(row)}>
                        {row.insights_url.replace('/', '') || 'Production'}
                      </TableCell>
                      <TableCell align="left">
                        <FiltersActionSection
                          filter={row}
                          storageKey={storageKey}
                          getSavedFilters={getSavedFilters}
                          setShowDeleteAlert={setShowDeleteAlert}
                        />
                      </TableCell>
                    </TableRow>
                  );
                })
              )}
              {emptyRows > 0 && (
                <TableRow
                  style={{
                    height: 53 * emptyRows
                  }}
                >
                  <TableCell colSpan={6} />
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={filters.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
    </Box>
  );
}

export function FiltersActionSection({
  filter,
  storageKey,
  getSavedFilters,
  setShowDeleteAlert
}: any) {
  const [open, setOpen] = React.useState(false);
  const [deleteLoader, setDeleteLoader] = React.useState(false);
  const handleTooltipClose = () => {
    setOpen(false);
  };

  const handleTooltipOpen = () => {
    setOpen(true);
  };
  const deleteFilter = async () => {
    const url = `${insightsConfig.baseURL.saveFiltersAPI}/RemoveSavedFilter`;
    try {
      setDeleteLoader(true);
      const reqBody = { user_id: storageKey, reference_id: filter.reference_id };
      await axios.post(url, reqBody).then(() => {
        setShowDeleteAlert('success');
        getSavedFilters();
      });
    } catch (err: any) {
      sendErrorsToCW(err, err.stack, storageKey, [], url);
      console.log(err);
    } finally {
      setDeleteLoader(false);
    }
  };

  const copyToClipBoard = (value: string) => {
    navigator.clipboard.writeText(value);
    handleTooltipOpen();
    setTimeout(() => {
      handleTooltipClose();
    }, 3000);
  };

  return (
    <ButtonGroup variant="text" aria-label="Basic button group">
      <IconButton
        onClick={() =>
          copyToClipBoard(window.location.origin + '/shared_url/' + filter.reference_id)
        }
      >
        <Tooltip
          PopperProps={{
            disablePortal: true
          }}
          onClose={handleTooltipClose}
          open={open}
          disableFocusListener
          disableHoverListener
          disableTouchListener
          TransitionComponent={Zoom}
          title="URL Copied to Clipboard"
          leaveDelay={600}
        >
          <ContentCopyOutlinedIcon fontSize="small" />
        </Tooltip>
      </IconButton>
      <IconButton onClick={() => deleteFilter()}>
        {deleteLoader ? (
          <CircularProgress size={15} color="warning" />
        ) : (
          <DeleteOutlinedIcon fontSize="small" />
        )}
      </IconButton>
    </ButtonGroup>
  );
}
