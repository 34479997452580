import { createContext } from 'react';

const FilterContext = createContext<IFilterContext>({});

export { FilterContext };

interface IFilterContext {
  iFrameLocation?: Record<any, any>;
  storageKey?: string;
}

const InspectionsContext = createContext<IInspectionsContext>({ inspections: {} });

export { InspectionsContext };

interface IInspectionsContext {
  inspections: Record<string, any>;
}
