import CompanyLogo from 'assets/GSAI_text_logo.svg';
import MenuIcon from 'assets/remix-icons/line/system/menu-4-line.svg';
import React from 'react';
import { useNavigate } from 'react-router';

import ProfileIcon from '../profile-icon';
import './header-bar.scss';

interface Props {
  userDetails: any;
  handleDrawerToggle: () => void;
  restrictedAccessUser: boolean;
  isAdmin: boolean;
}

function HeaderBar({ userDetails, handleDrawerToggle, restrictedAccessUser, isAdmin }: Props) {
  const clientImage = `${process.env.PUBLIC_URL}/clientLogo.svg`;
  const navigate = useNavigate();
  const navigateToHomePage = () => {
    navigate('/', { replace: true });
  };
  return (
    <div className="header-bar">
      {/* <div className="ham-menu">
            </div> */}
      <div className="client-logo">
        {userDetails && (
          <img
            onClick={handleDrawerToggle}
            className="ham-menu"
            src={MenuIcon}
            alt="clientLogo"
          ></img>
        )}
        <img
          className="client-logo-img"
          onClick={navigateToHomePage}
          src={clientImage}
          alt="clientLogo"
        ></img>
      </div>
      <div className="gs-logo-profile right-flex">
        {userDetails && (
          <div className="gs-profile">
            <ProfileIcon
              userDetails={userDetails}
              restrictedAccessUser={restrictedAccessUser}
              isAdmin={isAdmin}
            />
          </div>
        )}
        <img className="gs-logo" src={CompanyLogo} alt="companyLogo"></img>
      </div>
    </div>
  );
}

export default HeaderBar;
