import axios from 'api-axios';
import insightsConfig from 'config/config';
import { ErrorInfo } from 'react';

export const sendErrorsToCW = async (
  error: Error = {
    message: '',
    name: ''
  },
  errorInfo: ErrorInfo = {},
  userId = '',
  errors: any[] = [],
  url = ''
) => {
  const endpoint = `${insightsConfig.baseURL.logErrorsAPI}/LogInsightsError`;

  const timestamp = new Date().toISOString();
  const severity = 'error';

  const payload: any[] | undefined = [];

  if (error.message || error.stack || errorInfo.componentStack) {
    payload.push({
      error: error.message,
      stack: error.stack,
      errorInfo: JSON.stringify(errorInfo),
      userId: userId,
      timestamp: timestamp,
      severity: severity,
      browserInfo: navigator.userAgent,
      apiUrl: url
    });
  } else if (errors.length > 0) {
    errors.forEach((error) => {
      payload.push({
        error: error.message,
        stack: error.stack,
        refId: error.refId,
        userId: userId,
        timestamp: timestamp,
        severity: severity,
        browserInfo: navigator.userAgent,
        apiUrl: url
      });
    });
  }

  axios
    .post(endpoint, payload, {
      headers: {
        'Content-Type': 'application/json'
      }
    })
    .then(() => {
      console.log('Error sent to server');
    })
    .catch((err) => {
      console.error('Error sending error to server', err);
    });
};
