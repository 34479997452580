import insights_config from './config';

const aws_exports = {
  Auth: {
    region: insights_config.amplify.region,
    userPoolId: insights_config.amplify.userPoolId,
    userPoolWebClientId: insights_config.amplify.userPoolWebClientId,

    cookieStorage: {
      domain: insights_config.amplify.cookieDomain,
      path: '/',
      expires: 1,
      sameSite: 'strict',
      secure: true
    },

    oauth: {
      domain: insights_config.amplify.cognitoDomain,
      redirectSignIn: insights_config.amplify.redirectSignIn,
      redirectSignOut: insights_config.amplify.redirectSignOut,
      responseType: 'code',
      scope: ['phone', 'email', 'profile', 'openid']
    }
  }
};

export default aws_exports;
