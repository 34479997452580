import CloseIcon from '@mui/icons-material/Close';
import InspectionSvg from 'assets/Frame.svg';
import ProductionSvg from 'assets/Group 10531.svg';
import QualitySvg from 'assets/Group 10533.svg';
import CheckListIcon from 'assets/checklist.svg';
import ExportSvg from 'assets/export.svg';
import machineIcon from 'assets/machine.svg';
import JobsSvg from 'assets/remix-icons/line/business/profile-line-2.svg';
import MenuIcon from 'assets/remix-icons/line/system/menu-4-line.svg';
import WeightsSvg from 'assets/weights.svg';
import insightsConfig from 'config/config';
import React, { useCallback, useMemo } from 'react';
import { NavLink, useLocation } from 'react-router-dom';

import ProductBadge from './ProductsBadge';
import './leftnav.scss';

interface NavLinkItem {
  path: string;
  text: string;
  imgSrc?: string;
  altText: string;
  hasBadge?: boolean;
}

export const NAV_PATHS = {
  PRODUCTION: 'production',
  QUALITY: 'quality',
  WEIGHTS: 'weights',
  JOBS: 'jobs',
  INSPECTIONS: 'inspections',
  PRODUCT_CONFIG: 'productconfiguration',
  EXPORT_DATA: 'exportdata',
  MACHINE_HEALTH: 'machinehealth',
  TEST_CARDS: 'test-cards'
} as const;

export const VIEW_TYPES = {
  COMPANY: 'company_view',
  LOCATION: 'location_view',
  LINE: 'line_view'
} as const;

export const getNavPath = (base: string, isAdmin: boolean): string => {
  if (!insightsConfig.featureToggles.isEnableMultipleTabs) {
    return base === NAV_PATHS.PRODUCTION ? '/' : `/${base}`;
  }

  const viewType = isAdmin ? VIEW_TYPES.COMPANY : VIEW_TYPES.LOCATION;
  return `/${base}/${base}_${viewType}`;
};

export const buildNavLinks = (isAdmin: boolean): NavLinkItem[] => {
  const baseLinks: NavLinkItem[] = [
    {
      path: getNavPath(NAV_PATHS.PRODUCTION, isAdmin),
      text: 'Production',
      imgSrc: ProductionSvg,
      altText: 'Production'
    },
    {
      path: getNavPath(NAV_PATHS.QUALITY, isAdmin),
      text: 'Quality',
      imgSrc: QualitySvg,
      altText: 'Quality'
    },
    {
      path: '/jobs',
      text: 'Jobs',
      imgSrc: JobsSvg,
      altText: 'Jobs'
    },
    {
      path: '/inspections',
      text: 'Inspections',
      imgSrc: InspectionSvg,
      altText: 'Inspections'
    },
    {
      path: '/productconfiguration',
      text: 'Product Configuration',
      altText: 'Product Configuration',
      hasBadge: true
    },
    {
      path: '/exportdata',
      text: 'Exports',
      imgSrc: ExportSvg,
      altText: 'Export Data'
    },
    {
      path: '/machinehealth',
      text: 'Machines',
      imgSrc: machineIcon,
      altText: 'Machines'
    }
  ];

  const conditionalLinks: NavLinkItem[] = [];

  if (insightsConfig.featureToggles.isTestCardsEnabled) {
    conditionalLinks.push({
      path: '/test-cards',
      text: 'Test Cards',
      imgSrc: CheckListIcon,
      altText: 'Test Cards'
    });
  }

  if (insightsConfig.featureToggles.isEnableWeightsDashboard) {
    conditionalLinks.push({
      path: getNavPath(NAV_PATHS.WEIGHTS, isAdmin),
      text: 'Weights',
      imgSrc: WeightsSvg,
      altText: 'Weights'
    });
  }

  return [...baseLinks.slice(0, 2), ...conditionalLinks, ...baseLinks.slice(2)];
};

interface NavItemProps {
  link: NavLinkItem;
  isCustomActive: (tab: string) => boolean;
  onClick: () => void;
}

const NavItem: React.FC<NavItemProps> = React.memo(
  ({ link, isCustomActive, onClick }: NavItemProps) => {
    const isActiveLink = ({ isActive }: { isActive: boolean }) => {
      if (insightsConfig.featureToggles.isEnableMultipleTabs) {
        return isCustomActive(link.text.toLowerCase()) || isActive ? 'active-icon' : '';
      }
      return isActive ? 'active-icon' : '';
    };

    return (
      <li onClick={onClick}>
        <NavLink to={link.path} className={isActiveLink}>
          <div className="icon">
            {link.hasBadge ? <ProductBadge /> : <img src={link.imgSrc} alt={link.altText} />}
            <div className="icon-text">{link.text}</div>
          </div>
        </NavLink>
      </li>
    );
  }
);

NavItem.displayName = 'NavItem';

interface LeftNavProps {
  handleDrawerToggle?: () => void;
  isAdmin: boolean;
}

const LeftNav: React.FC<LeftNavProps> = ({ handleDrawerToggle, isAdmin }) => {
  const location = useLocation();

  const drawerToggleFnc = useCallback(() => {
    handleDrawerToggle?.();
  }, [handleDrawerToggle]);

  const isCustomActive = useCallback(
    (tab: string) => {
      const path = location.pathname;
      const viewTypes = Object.values(VIEW_TYPES);
      return viewTypes.some((viewType) => path === `/${tab}/${tab}_${viewType}`);
    },
    [location.pathname]
  );

  const navLinks = useMemo(() => buildNavLinks(isAdmin), [isAdmin]);

  return (
    <>
      <div className="respon-icon-bar">
        <div className="respon-bar">
          <img onClick={drawerToggleFnc} className="ham-menu" src={MenuIcon} alt="Menu" />
          <CloseIcon onClick={drawerToggleFnc} />
        </div>
      </div>

      <div className="icon-bar">
        <nav>
          <ul>
            {navLinks.map((link, index) => (
              <NavItem
                key={`${link.path}-${index}`}
                link={link}
                isCustomActive={isCustomActive}
                onClick={drawerToggleFnc}
              />
            ))}
          </ul>
        </nav>
      </div>
    </>
  );
};

export default React.memo(LeftNav);
