interface GrafanaConfigType {
  grafana_base_url: string;
  productionDashboardPath: string;
  qualityDashboardPath: string;
  jobDashboardPath: string;
  inspectionDashboardPath: string;
  weightsDashboardPath: string;
  inspectionTest: string;
  testCardsLinePath: string;
  productionDashboards: LinksType;
  qualityDashboards: LinksType;
  weightsDashboards: LinksType;
}

interface LinksType {
  [key: string]: string;
}

interface APIConfigType {
  api_base_url: string;
  viewInspection: string;
  searchInspection: string;
}

interface BaseURLConfigType {
  grafana: string;
  inspectionAPI: string;
  machineHealthInsightsAPI: string;
  customerProductConfigurationAPI: string;
  saveFiltersAPI: string;
  logErrorsAPI: string;
  userSessionAPI: string;
}

interface AmplifyConfigType {
  redirectSignOut: string;
  redirectSignIn: string;
  userPoolId: string;
  userPoolWebClientId: string;
  region: string;
  cognitoDomain: string;
  cookieDomain: string;
  isCognitoHostedUI: boolean;
  identityProvider: string;
  refreshTokenInterval: number;
  isBoundingBoxEnabled: boolean;
  isEnableWeightsDashboard: boolean;
  isEnableMultipleTabs: boolean;
}

declare global {
  interface Window {
    deployTimeConfig: {
      baseURL: BaseURLConfigType;
      grafana: GrafanaConfigType;
      api: APIConfigType;
      amplify: AmplifyConfigType;
    };
  }
}

const defaultConfig = {
  baseURL: {
    grafana:
      process.env && process.env.REACT_APP_GRAFANA_BASE_URL
        ? process.env.REACT_APP_GRAFANA_BASE_URL
        : 'http://localhost:3002/grafana',
    inspectionAPI:
      process.env && process.env.API_BASE_URL
        ? process.env.API_BASE_URL
        : 'http://localhost:3002/api',
    machineHealthInsightsAPI:
      process.env && process.env.MACHINE_HEALTH_API_URL ? process.env.MACHINE_HEALTH_API_URL : '',
    saveFiltersAPI:
      process.env && process.env.SAVE_FILTERS_API_URL ? process.env.SAVE_FILTERS_API_URL : '',
    logErrorsAPI:
      process.env && process.env.LOG_ERRORS_API_URL ? process.env.LOG_ERRORS_API_URL : '',
    userSessionAPI:
      process.env && process.env.USER_SESSION_API_URL ? process.env.USER_SESSION_API_URL : '',
    customerProductConfigurationAPI:
      process.env && process.env.PRODUCT_CONFIGURATION_API_URL
        ? process.env.PRODUCT_CONFIGURATION_API_URL
        : ''
  },
  amplify: {
    cookieDomain:
      process.env && process.env.COOKIE_DOMAIN ? process.env.COOKIE_DOMAIN : 'localhost',
    region: 'us-east-1',
    userPoolId:
      process.env && process.env.REACT_APP_USERPOOL_ID ? process.env.REACT_APP_USERPOOL_ID : '',
    userPoolWebClientId:
      process.env && process.env.REACT_APP_USERPOOL_WEBCLIENT_ID
        ? process.env.REACT_APP_USERPOOL_WEBCLIENT_ID
        : '',
    cognitoDomain:
      process.env && process.env.REACT_APP_COGNITO_DOMAIN
        ? process.env.REACT_APP_COGNITO_DOMAIN
        : '',
    redirectSignIn: 'http://localhost:3001',
    redirectSignOut: 'http://localhost:3002/signOut',
    isCognitoHostedUI: true,
    identityProvider: '',
    refreshTokenInterval: 50
  },
  grafana: {
    productionDashboardPath: '/d/SqSAZYh7zlv/production-line-view',
    qualityDashboardPath: '/d/yYB1ZL2nxlv/quality-line-view',
    jobDashboardPath: '/d/SqSAZYh7k/jobs',
    inspectionDashboardPath: '/d/SqSAZYh7l/inspections',
    weightsDashboardPath: '/d/SqSAZYh7wlv/weights-line-view',
    inspectionTest: '/d/WQfRCt32SDE/inspections',
    testCardsLinePath: '/d/QaNCWOECnpfv/test-cards-line-view',
    productionDashboards: {
      production_company_view: '/d/SqSAZYh7zcv/production-company-view',
      production_location_view: '/d/SqSAZYh7zfv/production-location-view',
      production_line_view: '/d/SqSAZYh7zlv/production-line-view'
    },
    qualityDashboards: {
      quality_company_view: '/d/yYB1ZL2nxcv/quality-company-view',
      quality_location_view: '/d/yYB1ZL2nxfv/quality-location-view',
      quality_line_view: '/d/yYB1ZL2nxlv/quality-line-view'
    },
    weightsDashboards: {
      weights_company_view: '/d/SqSAZYh7wcv/weights-company-view',
      weights_location_view: '/d/SqSAZYh7wfv/weights-location-view',
      weights_line_view: '/d/SqSAZYh7wlv/weights-line-view'
    }
  },
  GoogleAnalytics: {
    GA_ID: 'G-YGFJZ42Y4B',
    UA_ID: 'UA-228486763-1'
  },
  api: {
    viewInspection: '/inspections/:inspectionId',
    searchInspection: '/inspections/search'
  },
  featureToggles: {
    isBoundingBoxEnabled: true,
    isEnableWeightsDashboard: false,
    isEnableMultipleTabs: false,
    isCustomerManagedRoles: false,
    isTestCardsEnabled: false
  }
};

const deployTimeConfig = window.deployTimeConfig || {};

const insightsConfig = {
  ...defaultConfig,
  ...deployTimeConfig
};

export const uiConfigs: TuiConfigs = {
  showLeftNav: true
};

export default insightsConfig;

export type TuiConfigs = {
  showLeftNav: boolean;
};
