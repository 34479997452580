import { CognitoUserSession } from 'amazon-cognito-identity-js';
import { Auth } from 'aws-amplify';

import { AuthDetails } from './useAuth';

class AuthService {
  private static instance: AuthService;
  private authDetails: AuthDetails | null = null;
  private isRefreshingToken = false;

  private constructor() {
    // private constructor to prevent instantiation
  }

  static getInstance(): AuthService {
    if (!AuthService.instance) {
      AuthService.instance = new AuthService();
    }
    return AuthService.instance;
  }

  setAuthDetails(details: AuthDetails) {
    this.authDetails = details;
  }

  getAuthDetails(): AuthDetails | null {
    return this.authDetails;
  }

  async refreshTokenIfNeeded(): Promise<CognitoUserSession | null> {
    if (this.isRefreshingToken) {
      return this.authDetails?.userSession || null;
    }

    try {
      const currentSession = await Auth.currentSession();
      const expiresIn = currentSession.getIdToken().getExpiration() - Math.floor(Date.now() / 1000);

      if (expiresIn < 300) {
        this.isRefreshingToken = true;
        const cognitoUser = await Auth.currentAuthenticatedUser();
        const newSession = await new Promise<CognitoUserSession>((resolve, reject) => {
          cognitoUser.refreshSession(
            currentSession.getRefreshToken(),
            (err: any, session: CognitoUserSession) => {
              this.isRefreshingToken = false;
              if (err) {
                reject(err);
              } else {
                this.updateAuthDetails(session);
                resolve(session);
              }
            }
          );
        });
        return newSession;
      } else {
        this.updateAuthDetails(currentSession);
        return currentSession;
      }
    } catch (error) {
      this.isRefreshingToken = false;
      console.error('Error checking token expiration: ', error);
      return null;
    }
  }

  private updateAuthDetails(session: CognitoUserSession) {
    if (this.authDetails) {
      this.authDetails = {
        ...this.authDetails,
        isLoading: false,
        userSession: session
      };
    }
  }
}

export default AuthService;
