import { Divider } from '@mui/material';
import notFoundImg from 'assets/Group 10752.svg';
import { AdminContacts, GreyScaleContacts } from 'components/error-handlers/greyscale-contacts';
import { AuthContext } from 'hooks/useAuth';
import React, { useContext } from 'react';

import './not-found.scss';

interface Props {
  headerText?: string;
  body?: string[];
  footerText?: string;
  children?: React.ReactNode;
  showAddress?: boolean;
  overRideStyle?: any;
  svgSrc?: any;
}

function NotFound(props: Props) {
  const {
    headerText = 'Something went wrong!',
    footerText,
    showAddress = true,
    svgSrc = notFoundImg,
    overRideStyle
  } = props;
  const bodyNode = props.children as any;
  const { authDetails } = useContext(AuthContext);
  return (
    <main
      className="not-found-page"
      style={{
        padding: '1rem',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        height: 'fit-content'
      }}
    >
      <section>
        <img
          src={svgSrc}
          alt="404 - resource not found"
          {...(overRideStyle ? { style: overRideStyle } : {})}
        />
      </section>
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <p style={{ fontSize: '18px', fontWeight: '500' }}>{headerText}</p>
        {bodyNode ? (
          bodyNode
        ) : (
          <>
            <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>
              Please try again in some time.
            </p>
            <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>
              If the Problem persists, reach out us on
            </p>
          </>
        )}

        {showAddress && (
          <address
            style={{
              boxSizing: 'border-box',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              fontStyle: 'normal'
            }}
          >
            {!authDetails?.roleDetails?.session_id && authDetails?.roleDetails?.help_info ? (
              <div>
                <Divider style={{ padding: '10px' }} />
                <div
                  style={{
                    display: 'flex',
                    flexWrap: 'wrap',
                    flexDirection: 'column',
                    maxHeight: '25vh',
                    paddingTop: '10px',
                    gap: '8px'
                  }}
                >
                  {authDetails?.roleDetails?.help_info.map((contact, index) => (
                    <div key={index}>
                      <AdminContacts {...contact} />
                      <Divider style={{ padding: '5px' }} />
                    </div>
                  ))}
                </div>
              </div>
            ) : (
              <GreyScaleContacts footerText={footerText} />
            )}
          </address>
        )}
      </section>
    </main>
  );
}

export function NoExports(props: Props) {
  const bodyNode = props.children as any;
  // console.log('bodyNode', bodyNode);
  return (
    <main
      className="not-found-page"
      style={{
        padding: '1rem',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <section
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        {bodyNode ? (
          bodyNode
        ) : (
          <>
            <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>
              No Exports to display
            </p>
            <p style={{ fontSize: '14px', fontWeight: '400', color: '#666666' }}>
              You can Export data from Inspections Tab
            </p>
          </>
        )}
      </section>
    </main>
  );
}

export default NotFound;
