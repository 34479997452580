import { useMediaQuery } from '@mui/material';
import { Amplify } from 'aws-amplify';
import React, { useCallback, useEffect, useState } from 'react';
import ReactGA from 'react-ga4';
import { matchPath, useLocation } from 'react-router-dom';

import './App.scss';
import { LoginRoutes, RoutesConfig } from './components/Navigation/RoutesConfig';
import ResponsiveDrawer from './components/drawer/drawer';
import HeaderBar from './components/header-bar';
import LeftNav from './components/leftnav';
import Loading from './components/loading';
import aws_config from './config/aws_exports';
import insightsConfig from './config/config';
import { AuthContext, useAuth } from './hooks/useAuth';
import { FilterContext } from './hooks/useFilters';
import { checkIfAdmin, checkIfCompanyViewer, getKeyForStorage } from './utils';

Amplify.configure(aws_config);

const MOBILE_BREAKPOINT = '(max-width:768px)';
const TRACKING_ID = insightsConfig.GoogleAnalytics.GA_ID;

const App: React.FC = () => {
  const {
    authDetails,
    refreshTokenIfNeeded,
    updateUiConfigFnc,
    refreshSessionIfNeeded,
    isRefreshingRole
  } = useAuth();
  const location = useLocation();
  const isMobile = useMediaQuery(MOBILE_BREAKPOINT);
  const [drawerOpen, setDrawerOpen] = useState(false);

  const memoUpdateUiConfigFnc = useCallback(updateUiConfigFnc, [updateUiConfigFnc]);
  const storageKey = getKeyForStorage(authDetails);

  const {
    userSession,
    isLoading,
    roleDetails,
    uiConfigs: { showLeftNav }
  } = authDetails;

  useEffect(() => {
    ReactGA.initialize(TRACKING_ID, {
      gaOptions: {
        cookieDomain: insightsConfig.amplify.cookieDomain,
        cookieFlags: 'samesite=None;Secure'
      }
    });
  }, []);

  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: window.location.href
    });
    refreshTokenIfNeeded();
    refreshSessionIfNeeded();
  }, [location]);

  const handleDrawerToggle = useCallback(() => {
    setDrawerOpen((prev) => !prev);
  }, []);

  if (isLoading || isRefreshingRole) {
    return <Loading />;
  }
  if (userSession && !isLoading) {
    const originalUrl = sessionStorage.getItem('originalUrl');
    if (originalUrl) {
      sessionStorage.removeItem('originalUrl');
      window.location.assign(originalUrl);
      return null;
    }
  }

  if (!userSession && !matchPath(location.pathname, '/signOut')) {
    return <LoginRoutes />;
  }

  const restrictedAccessUser = !roleDetails?.session_id;
  const isAdmin = checkIfAdmin(roleDetails?.role);
  const isCompanyViewer = checkIfCompanyViewer(roleDetails?.role);
  return (
    <AuthContext.Provider
      value={{
        authDetails,
        updateUiConfigFnc: memoUpdateUiConfigFnc,
        refreshTokenIfNeeded,
        refreshSessionIfNeeded
      }}
    >
      <FilterContext.Provider value={{ storageKey }}>
        <div className="App">
          <header className="App-header">
            <HeaderBar
              userDetails={userSession?.idToken?.payload}
              restrictedAccessUser={restrictedAccessUser}
              handleDrawerToggle={handleDrawerToggle}
              isAdmin={isAdmin}
            />
          </header>
          {isMobile && !restrictedAccessUser && (
            <ResponsiveDrawer
              drawerOpen={drawerOpen}
              handleDrawerToggle={handleDrawerToggle}
              isAdmin={isCompanyViewer}
            />
          )}
          {showLeftNav && !isMobile && !restrictedAccessUser && (
            <div className="App-leftnav">
              <LeftNav isAdmin={isCompanyViewer} />
            </div>
          )}
          <section className="App-body">
            <div className={`App-content ${!showLeftNav ? 'App-content-full' : ''}`}>
              <RoutesConfig />
            </div>
          </section>
          <footer className="App-footer">
            <div className="footer-div">
              <p>Greyscale AI © {new Date().getFullYear()}</p>
              <p>Terms and Conditions</p>
            </div>
          </footer>
        </div>
      </FilterContext.Provider>
    </AuthContext.Provider>
  );
};

export default App;
