import { IMachineHealth } from 'components/table/utils';

interface InputData {
  EnvironmentData: {
    Prod: {
      Machines: IMachineHealth[];
    };
    Test: {
      Machines: IMachineHealth[];
    };
  };
  Customer: string;
  CustomerName: string;
}

interface MachineHealthData {
  [location: string]: {
    machines: IMachineHealth[];
    online: number;
    offline: number;
  };
}

export const preProcessMachineHealthData = (
  data: InputData
): { machineHealthData: MachineHealthData; Customer: string; CustomerName: string } => {
  if (!data) {
    return { machineHealthData: {}, Customer: '', CustomerName: '' };
  }

  const { Prod, Test } = data.EnvironmentData;
  const machineHealthData: MachineHealthData = {};

  const allMachines = [...Prod.Machines, ...Test.Machines];

  allMachines.forEach((machine) => {
    const location = machine.Location || 'Not Configured';

    if (!machineHealthData[location]) {
      machineHealthData[location] = { machines: [], online: 0, offline: 0 };
    }

    machineHealthData[location].machines.push(machine);

    if (machine.Status === 'Online') {
      machineHealthData[location].online += 1;
    } else {
      machineHealthData[location].offline += 1;
    }
  });

  return { machineHealthData, Customer: data.Customer, CustomerName: data.CustomerName };
};
