import { Container } from '@mui/material';
import React, { useEffect } from 'react';

import aws_config from '../../config/aws_exports';

interface Props {
  identityProvider: string;
  isCognitoHostedUI: boolean;
}

const CognitoLogIn = ({ identityProvider, isCognitoHostedUI }: Props): JSX.Element => {
  useEffect(() => {
    const originalUrl = window.location.href;
    if (originalUrl.includes('shared_url'))
      sessionStorage.setItem('originalUrl', window.location.href);
    logIn();
  }, []);

  const logIn = () => {
    const { domain, redirectSignIn, responseType } = aws_config.Auth.oauth;
    const { userPoolWebClientId } = aws_config.Auth;

    //OAuth SignIn
    let url = `https://${domain}/oauth2/authorize?identity_provider=${identityProvider}&redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${userPoolWebClientId}`;

    if (isCognitoHostedUI) {
      url = `https://${domain}/login?redirect_uri=${redirectSignIn}&response_type=${responseType}&client_id=${userPoolWebClientId}`;
    }

    window.location.assign(url);
  };

  /* eslint-disable react/jsx-handler-names */
  return <Container />;
  /* eslint-enable react/jsx-handler-names */
};

export default CognitoLogIn;
