import PriorityHighRoundedIcon from '@mui/icons-material/PriorityHighRounded';
import Badge from '@mui/material/Badge';
import ProdConfig from 'assets/prodConfig.svg';
import { useMissingProductConfigAPI } from 'hooks/useAsyncRequest';
import React from 'react';

import './leftnav.scss';

function ProductBadge() {
  const { showMissingProductsbadge } = useMissingProductConfigAPI();

  return (
    <Badge
      badgeContent={<PriorityHighRoundedIcon style={{ height: '12px', width: 'auto' }} />}
      invisible={!showMissingProductsbadge}
      color="warning"
    >
      <img src={ProdConfig} alt="Product Configuration"></img>
    </Badge>
  );
}

export default ProductBadge;
