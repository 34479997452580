import CognitoLogIn from 'components/cognito-login';
import { AuthContext } from 'hooks/useAuth';
import React, { Suspense, useContext } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import {
  adminRoles,
  checkIfAdmin,
  checkIfCompanyViewer,
  companyViewerRoles,
  factoryViewerRoles
} from 'utils';

import insightsConfig from '../../config/config';
import NotFound from '../../pages/error/not-found';
import Loading from '../loading';
import ProtectedRoute from './ProtectedRoute';

const LazyComponents = {
  Production: React.lazy(() => import('../../pages/production')),
  Quality: React.lazy(() => import('../../pages/quality')),
  Jobs: React.lazy(() => import('../../pages/jobs')),
  MultipleInspections: React.lazy(() => import('../../pages/multiple-insp/multiple-insp')),
  Inspection: React.lazy(() => import('../../pages/inspection/inspection')),
  InspectionDetails: React.lazy(() => import('../../pages/inspection/inspectionDetails')),
  MachineHealth: React.lazy(() => import('../../pages/machine_health/machine-health')),
  MachineMonitoring: React.lazy(() => import('../../pages/monitoring')),
  ProductConfig: React.lazy(() => import('../../pages/productConfig/product-config')),
  ExportData: React.lazy(() => import('../../pages/export/export-data')),
  Admin: React.lazy(() => import('../../pages/admin')),
  WeightsDashboard: React.lazy(() => import('../../pages/weights')),
  TestCards: React.lazy(() => import('../../pages/testcards')),
  NotFound: React.lazy(() => import('../../pages/error/not-found')),
  Profile: React.lazy(() => import('../../pages/profile')),
  SignInAgain: React.lazy(() => import('../../pages/sign_out')),
  HelpSupport: React.lazy(() => import('../../pages/help_support')),
  ExpiredSignInAgain: React.lazy(() => import('../../pages/expired_sign_out')),
  RedirectToActualUrl: React.lazy(() => import('../../pages/urlredirection/url-redirection')),
  CognitoLogOut: React.lazy(() => import('../cognito-logout'))
};

interface RouteDefinition {
  path: string;
  Component: React.ComponentType;
  roles: string[];
  children?: RouteDefinition[];
}

const ALL_ROLES = [...adminRoles, ...companyViewerRoles, ...factoryViewerRoles];

const getFeatureRoutes = (): RouteDefinition[] => {
  const baseRoutes: RouteDefinition[] = [
    {
      path: '/jobs',
      Component: LazyComponents.Jobs,
      roles: ALL_ROLES
    },
    {
      path: '/multiple-insp',
      Component: LazyComponents.MultipleInspections,
      roles: ALL_ROLES
    },
    {
      path: '/machinehealth',
      Component: LazyComponents.MachineHealth,
      roles: ALL_ROLES
    },
    {
      path: '/machine-monitoring',
      Component: LazyComponents.MachineMonitoring,
      roles: ALL_ROLES
    },
    {
      path: '/productconfiguration',
      Component: LazyComponents.ProductConfig,
      roles: ALL_ROLES
    },
    {
      path: '/exportdata',
      Component: LazyComponents.ExportData,
      roles: ALL_ROLES
    },
    {
      path: '/inspections',
      Component: LazyComponents.Inspection,
      roles: ALL_ROLES,
      children: [
        {
          path: ':inspectionId',
          Component: LazyComponents.InspectionDetails,
          roles: ALL_ROLES
        }
      ]
    },
    {
      path: '/shared_url/:sharedUrlId',
      Component: LazyComponents.RedirectToActualUrl,
      roles: ALL_ROLES
    }
  ];

  if (insightsConfig.featureToggles.isTestCardsEnabled) {
    baseRoutes.push({
      path: '/test-cards',
      Component: LazyComponents.TestCards,
      roles: ALL_ROLES
    });
  }

  return baseRoutes;
};

const getMultipleTabRoutes = (isCompanyViewer: boolean): RouteDefinition[] => {
  const companyRoutes: RouteDefinition[] = isCompanyViewer
    ? [
        {
          path: '/production/production_company_view',
          Component: LazyComponents.Production,
          roles: [...adminRoles, ...companyViewerRoles]
        },
        {
          path: '/quality/quality_company_view',
          Component: LazyComponents.Quality,
          roles: [...adminRoles, ...companyViewerRoles]
        },
        {
          path: '/weights/weights_company_view',
          Component: LazyComponents.WeightsDashboard,
          roles: [...adminRoles, ...companyViewerRoles]
        }
      ]
    : [];

  const userRoutes: RouteDefinition[] = [
    {
      path: '/production/production_location_view',
      Component: LazyComponents.Production,
      roles: ALL_ROLES
    },
    {
      path: '/production/production_line_view',
      Component: LazyComponents.Production,
      roles: ALL_ROLES
    },
    {
      path: '/quality/quality_location_view',
      Component: LazyComponents.Quality,
      roles: ALL_ROLES
    },
    { path: '/quality/quality_line_view', Component: LazyComponents.Quality, roles: ALL_ROLES }
  ];

  if (insightsConfig.featureToggles.isEnableWeightsDashboard) {
    userRoutes.push(
      {
        path: '/weights/weights_location_view',
        Component: LazyComponents.WeightsDashboard,
        roles: ALL_ROLES
      },
      {
        path: '/weights/weights_line_view',
        Component: LazyComponents.WeightsDashboard,
        roles: ALL_ROLES
      }
    );
  }

  return [...companyRoutes, ...userRoutes];
};

const getDefaultRoute = (isCompanyViewer: boolean): string =>
  isCompanyViewer ? '/production/production_company_view' : '/production/production_location_view';

export const RoutesConfig: React.FC = () => {
  const { authDetails } = useContext(AuthContext);
  const { roleDetails } = authDetails || {};

  const isRestrictedAccess = !roleDetails?.session_id;
  const isAdmin = checkIfAdmin(roleDetails?.role || '');
  const isCompanyViewer = checkIfCompanyViewer(roleDetails?.role || '');

  if (isRestrictedAccess) {
    return (
      <NotFound headerText="Access Restricted">
        Please contact your administrator for access and reload the page.
      </NotFound>
    );
  }

  const renderRoute = ({ path, Component, roles, children }: RouteDefinition) => (
    <Route
      key={path}
      path={path}
      element={<ProtectedRoute element={<Component />} allowedRoles={roles} />}
    >
      {children?.map(renderRoute)}
    </Route>
  );

  return (
    <Suspense fallback={<Loading />}>
      <Routes>
        {getFeatureRoutes().map(renderRoute)}

        {insightsConfig.featureToggles.isEnableMultipleTabs ? (
          <>
            {getMultipleTabRoutes(isCompanyViewer).map(renderRoute)}
            <Route path="/" element={<Navigate replace to={getDefaultRoute(isCompanyViewer)} />} />
            <Route
              path="/gsaiLogin"
              element={<Navigate replace to={getDefaultRoute(isCompanyViewer)} />}
            />
          </>
        ) : (
          <>
            <Route path="/gsaiLogin" element={<Navigate replace to="/" />} />
            <Route
              path="/"
              element={
                <ProtectedRoute allowedRoles={ALL_ROLES} element={<LazyComponents.Production />} />
              }
            />
            <Route
              path="/quality"
              element={
                <ProtectedRoute allowedRoles={ALL_ROLES} element={<LazyComponents.Quality />} />
              }
            />
            <Route
              path="/weights"
              element={
                <ProtectedRoute
                  allowedRoles={ALL_ROLES}
                  element={<LazyComponents.WeightsDashboard />}
                />
              }
            />
          </>
        )}

        {isAdmin && (
          <Route
            path="/ManageUsers"
            element={
              <ProtectedRoute allowedRoles={adminRoles} element={<LazyComponents.Admin />} />
            }
          />
        )}
        <Route path="/profile" element={<LazyComponents.Profile />} />
        <Route path="/signOut" element={<LazyComponents.SignInAgain />} />
        <Route path="/help" element={<LazyComponents.HelpSupport />} />
        <Route path="/404" element={<LazyComponents.NotFound />} />
        <Route path="/logOut" element={<LazyComponents.CognitoLogOut />} />
        <Route path="/sessionExpired" element={<LazyComponents.ExpiredSignInAgain />} />
        <Route
          path="*"
          element={
            <LazyComponents.NotFound headerText="URL Not Found" showAddress={false}>
              {' '}
            </LazyComponents.NotFound>
          }
        />
      </Routes>
    </Suspense>
  );
};

export const LoginRoutes: React.FC = () => {
  const { isCognitoHostedUI, identityProvider } = insightsConfig.amplify;
  return (
    <Routes>
      <Route path="/logOut" element={<LazyComponents.CognitoLogOut />} />
      <Route
        path="/gsaiLogin"
        element={
          <CognitoLogIn identityProvider="GsaiAzureAD" isCognitoHostedUI={isCognitoHostedUI} />
        }
      />
      <Route
        path="*"
        element={
          <CognitoLogIn identityProvider={identityProvider} isCognitoHostedUI={isCognitoHostedUI} />
        }
      />
    </Routes>
  );
};
