import { FilterContext } from 'hooks/useFilters';
import NotFound from 'pages/error/not-found';
import React, { Component, ErrorInfo, ReactNode } from 'react';
import { sendErrorsToCW } from 'utils/apiService';

interface ErrorBoundaryState {
  hasError: boolean;
  error: Error | null;
}

interface ErrorBoundaryProps {
  children: ReactNode;
}

type FilterContextType = React.ContextType<typeof FilterContext>;

class ErrorBoundary extends Component<ErrorBoundaryProps, ErrorBoundaryState> {
  state: ErrorBoundaryState = { hasError: false, error: null };

  static contextType = FilterContext;
  context!: FilterContextType;

  static getDerivedStateFromError(error: Error): ErrorBoundaryState {
    console.log('getDerivedStateFromError called with error:', error);
    return { hasError: true, error };
  }

  componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    sendErrorsToCW(error, errorInfo, this.context.storageKey || '', []);
  }

  render() {
    if (this.state.hasError) {
      return <NotFound headerText="Something went wrong!" />;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
