import Link from '@mui/material/Link';
import adminSvg from 'assets/admin.svg';
import MailSvg from 'assets/remix-icons/line/business/global-line.svg';
import ContactSvg from 'assets/remix-icons/line/device/phone-line.svg';
import React from 'react';

interface Props {
  footerText?: string;
}

interface AdminContactsProps {
  name: string;
  email_id: string;
}

function GreyScaleContacts(props: Props) {
  const { footerText } = props;
  return (
    <>
      {footerText ? (
        <p> {footerText}</p>
      ) : (
        <>
          <img src={MailSvg} alt="mail to" className="icon" style={{ padding: '0.5rem' }}></img>
          <Link
            href="mailto:service@greyscaleai.com"
            style={{
              marginRight: '15px',
              color: '#FF2A00',
              textDecorationColor: '#FF2A00',
              fontWeight: 'bold',
              fontSize: '14px'
            }}
          >
            service@greyscaleai.com
          </Link>
          <img
            src={ContactSvg}
            alt="contact at"
            className="icon"
            style={{ padding: '0.5rem' }}
          ></img>
          <Link
            href="tel:+16502327974"
            underline="none"
            style={{ color: '#000000', fontSize: '13px' }}
          >
            (650) 232-7974
          </Link>
        </>
      )}
    </>
  );
}

function AdminContacts(props: AdminContactsProps) {
  const { name, email_id } = props;
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img
          src={adminSvg}
          alt="contact admin"
          className="icon"
          style={{ padding: '0.5rem' }}
        ></img>
        <Link
          href={`mailto:${email_id}`}
          underline="none"
          style={{ color: '#000000', fontSize: '15px' }}
        >
          {name}
        </Link>
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <img src={MailSvg} alt="mail to" className="icon" style={{ padding: '0.5rem' }}></img>
        <Link
          href={`mailto:${email_id}`}
          style={{
            marginRight: '15px',
            color: '#FF2A00',
            textDecorationColor: '#FF2A00',
            fontWeight: 'bold',
            fontSize: '14px'
          }}
        >
          {email_id}
        </Link>
      </div>
    </>
  );
}

export { GreyScaleContacts, AdminContacts };
