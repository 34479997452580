import axios from 'axios';
import insightsConfig from 'config/config';
import AuthService from 'hooks/AuthService';

// Create an Axios instance with baseURL from insightsConfig
const axiosInstance = axios.create({
  baseURL: insightsConfig.baseURL.inspectionAPI,
  responseType: 'json',
  transformResponse: [
    function (data: any) {
      // Do whatever you want to transform the data
      //   console.log("data ", data);
      return data;
    }
  ]
});

// Axios request interceptor
axiosInstance.interceptors.request.use(
  async (config) => {
    try {
      const authService = AuthService.getInstance();
      const session = await authService.refreshTokenIfNeeded();
      if (session) {
        config.headers.CognitoAuthorization = session.getIdToken().getJwtToken();
      }
      return config;
    } catch (error) {
      console.error('Error in request interceptor:', error);
      return Promise.reject(error);
    }
  },
  (error) => {
    return Promise.reject(error); // Handle request errors
  }
);

// Axios response interceptor (Optional)
// Add a response interceptor to handle responses globally if needed
axiosInstance.interceptors.response.use(
  (response) => {
    return JSON.parse(response.data); // You can modify the response data here if needed
  },
  (error) => {
    console.error('Error in response interceptor:', error);
    return Promise.reject(error); // Handle response errors
  }
);

export default axiosInstance;
