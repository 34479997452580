import React, { useContext } from 'react';
import { Navigate } from 'react-router-dom';

import { AuthContext } from '../../hooks/useAuth';

interface ProtectedRouteProps {
  allowedRoles: string[];
  element: React.ReactElement;
}

const ProtectedRoute: React.FC<ProtectedRouteProps> = ({ allowedRoles, element }) => {
  const { authDetails } = useContext(AuthContext);
  const userRole = authDetails?.roleDetails?.role;

  if (!userRole) {
    return <Navigate to="/" replace />;
  }

  const hasAccess = allowedRoles.includes(userRole);

  if (!hasAccess) {
    return <Navigate to="/" replace />;
  }

  return element;
};

export default ProtectedRoute;
