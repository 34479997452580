import CircularProgress from '@mui/material/CircularProgress';
import React from 'react';

interface Props {
  text?: string;
  height?: string;
}

function Loading({ text, height }: Props) {
  return (
    <main
      style={{
        height: height ?? 'calc(100vh - 148px)',
        padding: '1rem',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress style={{ color: '#FF5300' }} />
      <p style={{ fontSize: '16px', fontWeight: '500', color: '#000000' }}>{text}</p>
    </main>
  );
}

export function LoadingDiv({ text }: Props) {
  return (
    <div
      style={{
        padding: '1rem',
        paddingLeft: '4.5rem',
        boxSizing: 'border-box',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center'
      }}
    >
      <CircularProgress style={{ color: '#FF5300' }} />
      <p style={{ fontSize: '16px', fontWeight: '500', color: '#000000' }}>{text}</p>
    </div>
  );
}

export default Loading;
