import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import React from 'react';

interface Props {
  title: string;
  content: string;
  actionYes: string;
  actionNo: string;
  open: boolean;
  handleDialogClose: (agree: boolean) => void;
}

export default function ConfirmationDialog({
  title,
  content,
  actionYes,
  actionNo,
  open,
  handleDialogClose
}: Props) {
  return (
    <Dialog
      open={open}
      onClose={() => handleDialogClose(false)}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle
        id="alert-dialog-title"
        style={{ fontSize: '18px', fontWeight: '700', color: '#000000' }}
      >
        {title}
      </DialogTitle>
      <DialogContent>
        <DialogContentText
          id="alert-dialog-description"
          style={{ fontSize: '14px', fontWeight: '400', color: '#000000' }}
        >
          {content}
        </DialogContentText>
      </DialogContent>
      <DialogActions
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          padding: '16px 24px',
          paddingTop: '0'
        }}
      >
        <Button
          onClick={() => handleDialogClose(false)}
          variant="outlined"
          style={{
            fontSize: '14px',
            color: '#FF5300',
            borderColor: '#FF5300',
            borderRadius: '0px'
          }}
        >
          {actionNo}
        </Button>
        <Button
          onClick={() => handleDialogClose(true)}
          autoFocus
          variant="contained"
          style={{
            fontSize: '14px',
            color: '#ffffff',
            backgroundColor: '#FF5300',
            borderRadius: '0px'
          }}
        >
          {actionYes}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
